<template>
  <!-- deactivate START -->
  <div class="account-deactivate">
    <!-- form:qrcode START -->
    <el-form
      status-icon
      :rules="rules"
      ref="deleteForm"
      :model="deleteForm"
      class="login-form"
      :statusIcon="false"
      @submit.prevent.native="submitForm('deleteForm')"
    >
      <!-- form:qrcode:password:old-password START -->
      <el-form-item prop="currentPassword">
        <el-input
          :type="type"
          :placeholder="`${$t('login.currentpassword')} *`"
          v-model="deleteForm.currentPassword"
          @keyup.enter.native="submitForm('deleteForm')"
          autocomplete="off"
        >
          <i
            slot="suffix"
            class="el-icon-view"
            v-on:click="showHidePassword()"
          ></i>
        </el-input>
      </el-form-item>
      <!-- form:qrcode:password:old-password END -->

      <!-- form:qrcode:submit START -->
      <el-form-item>
        <el-button
          class="btn-default"
          type="primary"
          v-on:click="openDialog('delete')"
        >{{ $t('login.send') }}</el-button>

      </el-form-item>
      <!-- form:qrcode:submit END -->

    </el-form>
    <!-- form:qrcode END -->

    <!-- form:qrcode:loading START -->
    <loading-default
      :loadingVisible="loadingVisible"
      :headline="loadingHeadline"
      :excerpt="loadingExcerpt"
    />
    <!-- form:qrcode:loading END -->

    <!-- delete:modal START -->
    <dialog-confirm
      :loadingVisible="loadingDialogVisible"
      :headline="loadingDialogHeadline"
      :excerpt="loadingDialogExcerpt"
      :open="openDialog"
      :close="closeDialog"
      :confirm="confirmDialog"
      :type="typeConfirmDialog"
    />
    <!-- delete:modal END -->
  </div>
  <!-- deactivate END -->
</template>

<script>
import titleMixin from '@/mixins/titleMixin';

export default {
  name: 'deactivate',
  mixins: [titleMixin],
  components: {
    LoadingDefault: () => import('../../components/shared/loading/Default.vue'),
    DialogConfirm: () => import('../../components/shared/dialog/Confirm.vue'),
  },
  data() {
    return {
      /**
       * Title (Page) - If "pageTitleFrom" exists - show simple Navigation...
       */
      pageTitleFromComponent: this.$t('account.deactivate.headline'),

      /**
       * Loading
       */
      loadingVisible: false,
      loadingHeadline: this.$t('loading.update.headline'),
      loadingExcerpt: this.$t('loading.update.excerpt'),

      /**
       * Dialog
       */
      loadingDialogVisible: false,
      loadingDialogHeadline: this.$t('account.deactivate.headline'),
      loadingDialogExcerpt: this.$t('dialog.user'),
      typeConfirmDialog: null,

      /**
       * Form (Variables)
       */
      serverErrorMessages: [],
      globalErrorMessage: false,
      type: 'password',

      /**
       * Form (Models) + Pass data to mixin
       */
      deleteForm: {
        currentPassword: '',
      },
      /**
       * Form (Rules - Validation)
       */
      rules: {
        currentPassword: [
          {
            required: true,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
          {
            validator: this.validatePassword,
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      // Close dialog
      this.closeDialog();

      const formData = new FormData();
      if (formName === 'deleteForm') {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loadingVisible = true;
            /**
             * Locale
             */
            formData.append('locale', localStorage.getItem('lang'));
            /**
             * Data
             */
            formData.append('password', this.deleteForm.currentPassword);

            // Display the key/value pairs
            /* [...formData.entries()].forEach((row) => {
              console.log(`${row}`);
            }); */

            // Update user data
            this.$http.post(`${process.env.VUE_APP_BASE_URL}user/deactivate`, formData, {
              headers: {
                'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
                Authorization: `Bearer ${localStorage.getItem('auth')}`,
              },
            })
              .then((response) => {
                if (response.data) {
                  // Close loading
                  this.loadingVisible = false;

                  // notification - error
                  this.$router.push('/login');
                } else {
                  this.globalErrorMessage = true;
                }
              })
              .catch((error) => {
                if (!error.response) {
                  // network error
                  this.errorStatus = 'Error: Network Error';
                } else {
                  this.errorStatus = error.response.data;
                  this.loadingVisible = false;
                  this.serverErrorMessages = this.errorStatus.errors;

                  this.serverErrorMessages.forEach((message) => {
                    this.error(`${this.$t(`error.${message.code}`)}`);
                  });
                }
              });
          }
        });
      }
    },

    resetForm(formName) {
      if (this.$refs[formName]) {
        this.globalErrorMessage = false;
        this.$refs[formName].resetFields();
        this.type = 'password';
      }
    },

    showHidePassword() {
      if (this.type === 'password') {
        this.type = 'text';
      } else {
        this.type = 'password';
      }
    },

    success() {
      this.$notify({
        message: this.$t('notification.success'),
        type: 'success',
        position: 'bottom-right',
      });

      // clean up input fields
      setTimeout(() => {
        this.resetForm('qrcodeForm');
      }, 750);
    },

    error(message) {
      this.$notify({
        message: this.$t(`${message}`),
        type: 'error',
        position: 'bottom-right',
      });
    },

    openDialog(type) {
      if (type === 'delete') {
        this.typeConfirmDialog = 'delete';
      }
      this.loadingDialogVisible = true;
    },

    closeDialog() {
      this.loadingDialogVisible = false;
    },

    confirmDialog(type) {
      if (type === 'delete') {
        this.submitForm('deleteForm');
      }
    },
  },
};
</script>
